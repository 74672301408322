.hds-code-block__code {
	// Set padding-right to 0 to allow line-highlight to extend to edge.
	padding-right: 0;

	.hds-code-block & {
		// HDS factors the padding to the left of the lines of code into the
		// padding of the code block. Since we're adding that padding to the
		// line of code elements, we don't need it on the code block itself.
		padding-left: 0;
	}

	.hds-code-block.line-numbers & {
		// HDS factors the padding to the left of the lines of code into the
		// padding of the code block. Since we're adding that padding to the
		// line of code elements, we don't need it on the code block itself.
		padding-left: 49px;
	}

	.line-highlight {
		// HDS uses a negative margin to keep things aligned, so we reset it.
		margin-top: 0px;

		// Reset borders to only show on left side. We render multiple
		// line-height elements to highlight multiple lines, but HDS uses one
		// element to highlight contiguous lines. Since we're stacking
		// highlights on top of one another, we need to disable the top and
		// bottom borders.
		border-width: 0 0 0 4px;

		// Enable the top border if this is the first line in a group of lines
		&.line-highlight-first {
			border-top-width: 1px;
		}

		// Enable the bottom border if this is the last line in a group of lines
		&.line-highlight-last {
			border-bottom-width: 1px;
		}

		// HDS uses absolute positioning to render the line highlight, but we
		// want to use relative positioning to keep the highlight within the
		// code block.
		position: unset;
	}

	.line-of-code {
		// Lines of code are <span> elements which are inline by default, but
		// we want them to take up the full width of the code block.
		display: block;

		// When we're rendering line numbers, we need to add padding to the
		// left of lines of code to make room for the line numbers.
		padding-left: var(--hdsplus-spacing-05);
		

		&.line-highlight {
			// This accounts for the 4px margin on the left side so the content
			// does not get moved by the margin.
			padding-left: var(--hdsplus-spacing-04);

			.line-numbers & {
				// This disables the thicker left border on highlighted lines.
				border-width: 0;

				// This resets the left padding to the original value.
				padding-left: var(--hdsplus-spacing-05);
			}

			// Enable the top border if this is the first line in a group of lines
			&.line-highlight-first {
				border-top-width: 1px;
			}

			// Enable the bottom border if this is the last line in a group of lines
			&.line-highlight-last {
				border-bottom-width: 1px;
			}
		}

		& > span {
			padding-right: var(--hdsplus-spacing-09);
		}
	}

	.line-number {
		// Line numbers are <span> elements which are inline by default, but we
		// want them to behave like block elements.
		display: block;

		// HDS uses the :before pseudo-element to render line numbers, but we're using
		// actual text nodes, so we need to specify the color.
		color: #999;
	}
}
