@import '../select/style.scss';

.hds-form-super-select__root {
	position: relative;
	width: 100%;
}

.hds-form-super-select__trigger {
	@extend .hds-form-select;
	@extend .hds-form-select--size-large;
	width: 100%;
	cursor: pointer;
}

.hds-form-super-select__search-input-wrapper {
	width: 100%;
	padding: 11px 11px 1px 11px;
	& input {
		padding: 7px;
	}
}

.hds-form-super-select__popover {
	overflow: hidden;
	position: absolute;
	top: calc(100% + 4px);
	left: 0;
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: var(--token-color-surface-primary);
	border-radius: 6px;
	box-shadow: var(--token-surface-high-box-shadow);
	z-index: 2;
}

.hds-form-super-select__list {
	list-style: none;
	margin: 0;
	padding: 3px 0;
	overflow-y: auto;
}

.hds-form-super-select__option {
	min-height: 36px;
	padding: 7px 7px 7px 40px;
	isolation: isolate; // used to create a new stacking context (needed to have the pseudo element below text/icon but not the parent container)
	cursor: pointer;
	position: relative; // needed to have the pseudo element below text/icon but not the parent container
	line-height: var(--token-typography-body-100-line-height);
}

.hds-form-super-select__option-text {
	// the bar that appears when hovering or "focusing" on an option
	&::after {
		position: absolute;
		z-index: -1;
		left: 4px;
		width: 2px;
		top: 6px;
		bottom: 6px;
		border-radius: 1px;
		content: '';
		background-color: var(--token-color-foreground-action-hover);
		display: none;
	}

	.hds-form-super-select__option:hover &::after,
	.hds-form-super-select__option[data-vfocused="true"] &::after {
		display: block;
	}

	.hds-form-super-select__option:hover &,
	.hds-form-super-select__option[data-vfocused="true"] & {
		color: var(--token-color-foreground-action-hover);
	}
}
